<template>
  <div class="outer-page">
    <div style="display: flex; flex-wrap: wrap">
      <div class="search-tree">
        <p
          class="item-box"
          :style="{
            background: siteType == index + 1 ? 'rgba(204, 204, 204,0.4)' : '',
          }"
          v-for="(item, index) in searchList"
          :key="index"
          @click="chooseType(item)"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="video-pane">
        <div class="search-pane">
          <Select style="width: 240px; margin-right: 20px" v-model="farmId">
            <Option
              :value="item.id"
              v-for="(item, index) in farmList"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
          <Button type="primary">查询</Button>
        </div>
        <div class="video-list" v-for="(item, index) in httpUrls" :key="index">
          <!-- :src="item.servicePath + item.httpUrl"
          v-if="item.httpUrl" -->
          <!-- <iframe
          scrolling="no"
          style="width: 360px; height: 208px"
          allowfullscreen="true"
        ></iframe> -->
          <div
            style="width: 360px; height: 208px; overflow: hidden"
            v-if="item.httpUrl"
          >
            <player :videoItem="item"></player>
          </div>
          <div class="divice-status" v-if="item.httpUrl">
            <span>{{ item.farmName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import player from "@/components/player/videoBox";
export default {
  name: "",
  components: {
    player,
  },
  data() {
    return {
      httpUrls: [],
      httpUrl: [],
      searchList: [
        { name: "种植企业", siteType: 1 },
        { name: "养殖企业", siteType: 2 },
        { name: "集散加工", siteType: 3 },
        { name: "社会组织", siteType: 4 },
      ],
      siteType: 1,
      farmList: [],
      farmId: "",
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.farmId,
      }).then((res) => {
        this.httpUrls = res.list;
        res.list.forEach((item) => {
          if (item.deviceType == 2) {
            this.httpUrl.push(item.httpUrl);
          }
        });
      });
    },
    getFarmList() {
      this.$post(this.$api.FAMRMING.FARMLIST, {
        siteType: this.siteType,
      }).then((res) => {
        this.farmList = res.list;
        this.farmId = res.list[0].id;
      });
    },
    chooseType(val) {
      this.siteType = val.siteType;
      this.getFarmList();
    },
  },
  mounted() {
    this.getFarmList();
  },
  watch: {
    farmId(val) {
      if (val) {
        this.getList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  height: 100%;
  padding: 20px;
  padding-left: 50px;
  .search-tree {
    width: 240px;
    box-shadow: rgb(204 204 204) 0px 0px 4px 0px;
    margin-right: 20px;
    padding: 20px;
    .item-box {
      margin-bottom: 20px;
      cursor: pointer;
      padding: 4px;
      padding-left: 10px;
    }
  }
  .search-pane {
    width: 100%;
    // height: 80px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }
  .video-pane {
    box-shadow: rgb(204 204 204) 0px 0px 4px 0px;
    width: calc(100% - 260px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 90px;
    padding-top: 30px;
    .video-list {
      width: 360px;
      height: 240px;
      margin-right: 35px;
      margin-bottom: 30px;
      .divice-status {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>