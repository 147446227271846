<template>
  <div style="width: 100%; height: 100%" class="base_info">
    <video
      :id="videoId"
      class="video-js"
      muted
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  props: {
    videoItem: Object | Number | Array | String,
  },
  data() {
    return {
      videoId: "online",
      player: null,
      playlist: [],
      //video视频配置
      options: {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
      },
    };
  },
  watch: {
    videoItem(val) {
      this.$nextTick((r) => {
        this.setVideo(val);
      });
    },
  },
  created() {
    this.$nextTick((r) => {
      this.setVideo(this.videoItem);
    });
  },
  methods: {
    //动态切换video视频
    setVideo(item) {
      this.videoId =
        "online" + item.farmId + Math.floor(Math.random() * (99999999 - 1)) + 1;
      this.$post(this.$api.BUSSDEVICEVIDEO.PREVIEW, {
        deviceKey: item.httpUrl,
      }).then((res) => {
        this.getVideo(res.url);
      });
    },
    getVideo(url) {
      this.player = videojs(this.videoId, this.options);
      this.player.src([
        {
          src: url,
          type: "application/x-mpegURL", // 告诉videojs,这是一个m3u8流
        },
      ]);
      this.playlist.push(this.player);
    },
  },
  beforeDestroy() {
    for (let i = 0; i < this.playlist.length; i++) {
      this.playlist[i].dispose(); //销毁函数
    }
  },
};
</script>

<style lang="less" scoped>
//视频操控组件
.video-js {
  width: 100%;
  height: 100%;
  overflow: hidden;
  > video {
    width: 100%;
    height: 100%;
  }
}
//视频操控组件
/deep/ .vjs-control-bar {
  z-index: 99;
}
/deep/ .vjs-loading-spinner {
  margin: -25px 0 0 -25px !important;
}
/deep/ .vjs-error-display {
  display: none !important;
}
/deep/ .vjs-big-play-button{
  display: none;
}
</style>